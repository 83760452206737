// @flow

const containerType = {
  page: 'page',
  dialog: 'dialog',
};

type ContainerType = $Keys<typeof containerType>;

const studySetTabType = {
  Terms: 'TERMS',
  Progress: 'PROGRESS',
  Leaders: 'LEADERS',
};

const appBarToolIconTypes = {
  favorite: 'Add to favorites',
  feedback: 'Feedback',
  info: 'Info',
};

const APP_CONTENT_HEADER_MIN_WIDTH = 144;
const CONTENT_LIST_MIN_WIDTH = 1020;
const BREADCRUMBS_ICON = 20;
const STANDARD_ICON = 24;
const LIST_ITEM_PROGRESS_RING = 48;
const INNER_RADIUS_SMALL = 19;
const RADIUS_SMALL = 24;
const DESCRIPTION_MIN_WIDTH = 992;
const APP_CONTENT_HEADER_PROGRESS_RING = 88;
const RADIUS_LARGE = 44;
const INNER_RADIUS_LARGE = 35;
const SMART_FOLDER_ICON = 50;
const LEARNING_CONTENT_HERO_ICON = 40;
const STUDY_CONTENT_ICON = 24;

export {
  appBarToolIconTypes,
  containerType,
  studySetTabType,
  LEARNING_CONTENT_HERO_ICON,
  APP_CONTENT_HEADER_MIN_WIDTH,
  BREADCRUMBS_ICON,
  CONTENT_LIST_MIN_WIDTH,
  STANDARD_ICON,
  LIST_ITEM_PROGRESS_RING,
  INNER_RADIUS_SMALL,
  RADIUS_SMALL,
  DESCRIPTION_MIN_WIDTH,
  APP_CONTENT_HEADER_PROGRESS_RING,
  RADIUS_LARGE,
  INNER_RADIUS_LARGE,
  STUDY_CONTENT_ICON,
  SMART_FOLDER_ICON,
};

export type {
  ContainerType,
};
