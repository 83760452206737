// @flow
/* -----------------Globals--------------- */
import React, { useState } from 'react';

/* -----------------Third parties--------------- */
import { I18n } from '@aws-amplify/core';
import clsx from 'clsx';
import {
  isIOS,
  isMacOs,
} from 'react-device-detect';
/* -----------------UI--------------- */
import {
  Typography,
  Fab,
  CircularProgress,
  Divider,
  Box,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

/* -----------------Icons--------------- */
import {
  Facebook,
  Apple,
  EmailOutline,
} from 'mdi-material-ui';
// import Google from '@images/Google.svg';

/* -----------------------  Actions ------------------ */
import {
  signInUsingSocialAuth,
} from '@components/actions/auth';

/* -----------------Functions--------------- */
import { getErrorMessage } from 'common/auth/functions';

/* ----------------- Constants --------------- */
import {
  socialAuth,
} from 'common/auth/constants';

/* -----------------Utils--------------- */
import {
  store,
  key,
} from '../../common/authutils';

/* -----------------Assets--------------- */
import DefaultAvatar from '../../../../public/static/splash/AvatarSample.png';
import { Google } from '@components/icons';

/* -----------------Styles--------------- */
const useStyles = makeStyles(theme => ({
  fabAuth: {
    borderRadius: 24,
    width: '100%',
    '&:hover': {
      filter: 'brightness(1.2)',
      transition: '0.2s all ease-out',
    },
  },
  fabAuthLabel: {
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(2),
  },
  fabAuthIcon: {
    color: theme.palette.common.white,
    position: 'absolute',
    left: 16,
  },
  facebookIcon: {
    fontSize: 36,
    color: theme.palette.common.white,
    position: 'absolute',
    left: 8,
  },
  appleIcon: {
    color: theme.palette.common.white,
    position: 'absolute',
    left: 16,
  },
  googleIconContainer: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 12,
    top: 8,
    height: 32,
    width: 32,
    borderRadius: '50%',
  },
  googleIcon: {
    fontSize: 20,
  },
  facebook: {
    backgroundColor: theme.palette.social.facebook,
    '&:hover': {
      backgroundColor: theme.palette.social.facebook,
    },
  },
  google: {
    backgroundColor: theme.palette.social.google,
    '&:hover': {
      backgroundColor: theme.palette.social.google,
    },
  },
  apple: {
    backgroundColor: theme.palette.social.apple,
    '&:hover': {
      backgroundColor: theme.palette.social.apple,
    },
  },
  email: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.text.secondary,
    color: theme.palette.text.primary,
    borderWidth: 2,
    borderStyle: 'solid',
    boxShadow: 'none',
    '&:hover': {
      filter: 'brightness(1)',
      transition: '0.2s all ease-out',
    },
  },
  iconEmail: {
    color: theme.palette.text.primary,
  },
  dividerText: {
    flex: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'center',
    fontSize: theme.fontSizes.body2,
  },
  dividerLine: {
    flex: 5,
  },
  divider: {
    margin: theme.spacing(3, 0, 2, 0),
  },
}));

/* -----------------Props--------------- */
type Props = {
  title: string,
  subtitle?: string,
  disableSocialLogin: boolean,
  showEmailInput: boolean,
  variant: string,
  handleShowEmailInput: *,
}

/* -----------------Component--------------- */
const AuthHeader = (props: Props) => {
  const {
    title,
    subtitle,
    variant,
    disableSocialLogin,
    showEmailInput,
    handleShowEmailInput,
  } = props;
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const showAppleLogin = isIOS || isMacOs;
  const handleSocialLogin = async (provider) => {
    try {
      await signInUsingSocialAuth(provider);
      await store(key.loggedIn, 'true');
      await store(key.loginSource, provider);
      await store(key.userIconUrl, DefaultAvatar);
    } catch (err) {
      setLoading(false);
      const error = getErrorMessage(err);
      setErrorMessage(error);
    }
    setLoading(false);
  };

  const socialLogins = [
    {
      type: 'facebook',
      action: () => handleSocialLogin(socialAuth.facebook),
      icon: <Facebook className={classes.facebookIcon} />,
    },
    {
      type: 'google',
      action: () => handleSocialLogin(socialAuth.google),
      icon: (
        <Box
          className={classes.googleIconContainer}
        >
          <Google className={classes.googleIcon} />
        </Box>
      ),
    },
    ...(showAppleLogin ? [{
      type: 'apple',
      action: () => handleSocialLogin(socialAuth.apple),
      icon: <Apple className={classes.appleIcon} />,
    }] : []),
  ];

  const emailLogin = {
    type: 'email',
    action: handleShowEmailInput,
    icon: <EmailOutline className={clsx(classes.fabAuthIcon, classes.iconEmail)} />,
  };

  const FabAuth = (p) => {
    const { type, action, icon } = p;
    const getCTA = () => {
      if (type === 'email') {
        switch (variant) {
          case 'signup':
            return 'SIGN UP';
          case 'login':
            return 'LOGIN';
          default:
            return 'CONTINUE';
        }
      } else {
        return 'CONTINUE';
      }
    };
    const CTA = getCTA();
    return (
      <Fab
        variant="extended"
        classes={{
          root: clsx(classes.fabAuth, classes[type]),
          label: (type !== 'email' && classes.fabAuthLabel),
        }}
        onClick={action}
        disabled={disableSocialLogin}
      >
        {isLoading ? (
          <CircularProgress
            color="secondary"
            size={24}
            className={classes.fabAuthIcon}
          />
        ) : icon}
        {`${CTA} with ${type}`}
      </Fab>
    );
  };

  return (
    <>
      <Box mb={3}>
        <Typography component="h1" variant="h5" align="center">
          {I18n.get(title)}
        </Typography>
        {subtitle
          ? (
            <Typography variant="subtitle1" align="center" color="textSecondary">
              <em>{I18n.get(subtitle)}</em>
            </Typography>
          ) : null }
      </Box>
      {socialLogins.map(login => (
        <Box my={2}>
          <FabAuth {...login} />
        </Box>
      ))}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        classes={{ root: classes.divider }}
      >
        <Divider classes={{ root: classes.dividerLine }} />
        <Typography variant="overline" className={classes.dividerText}>{I18n.get('OR')}</Typography>
        <Divider classes={{ root: classes.dividerLine }} />
      </Box>
      {!showEmailInput
        ? (
          <Box my={3}>
            <FabAuth {...emailLogin} />
          </Box>
        )
        : null}
      {
        errorMessage ? (
          <Typography variant="body2" color="error">{errorMessage}</Typography>
        ) : null
      }
    </>
  );
};

export default AuthHeader;
