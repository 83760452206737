// @flow
/* -----------------Globals--------------- */
import React, { useState } from 'react';

/* -----------------Nextjs--------------- */
import Router, {
  useRouter,
} from 'next/router';

/* -----------------Types--------------- */
import type {
  EmptyAction,
} from 'common/types';

/* ------------------------- Constants ----------------- */
import {
  containerType,
  type ContainerType,
} from '../common/constants';

/* -----------------Child components--------------- */
import AuthHeader from './AuthHeader';
import AuthInput from './AuthInput';
import AuthFooter from './AuthFooter';

/* -----------------Props--------------- */
type Props = {
  displayMode: ContainerType,
  handleSignUp?: EmptyAction,
  handleForgot?: EmptyAction,
  dialogTitle?: string,
  dialogSubtitle?: string,
  refreshAuth: *
};

/* -----------------Component--------------- */
const SignIn = (props: Props) => {
  const {
    displayMode,
    handleSignUp,
    handleForgot,
    refreshAuth,
    dialogTitle,
    dialogSubtitle,
  } = props;

  const [disableSocialLogin, setDisableSocialLogin] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const {
    query,
  } = useRouter();
  const redirectUrl = query && query.redirectUrl ? `/create-account?redirectUrl=${Router.query.redirectUrl}`
    : '/create-account';

  return (
    <>
      <AuthHeader
        title={dialogTitle || 'Log In'}
        subtitle={dialogSubtitle}
        variant="login"
        disableSocialLogin={disableSocialLogin}
        showEmailInput={showEmailInput}
        handleShowEmailInput={setShowEmailInput}
      />
      {showEmailInput ? (
        <AuthInput
          variant="login"
          refreshAuth={refreshAuth}
          disableSocialLogin={setDisableSocialLogin}
        />
      ) : null}
      <AuthFooter
        variant="login"
        primaryAction={() => (displayMode !== containerType.dialog
          ? Router.push('/forgot')
          : handleForgot && handleForgot())
        }
        secondaryAction={() => (displayMode !== containerType.dialog
          ? Router.push(redirectUrl)
          : handleSignUp && handleSignUp())
        }
      />
    </>
  );
};

SignIn.defaultProps = {
  handleSignUp: () => {},
  handleForgot: () => {},
};

export default SignIn;
