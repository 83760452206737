// @flow
/* -----------------Nextjs--------------- */
import Router from 'next/router';

import {
  key,
  retrieve,
} from './common/authutils';

const navigateTo = (
  href: string,
  as?: string,
  shallow?: boolean,
  replaceRoute?: boolean,
) => {
  if (replaceRoute) {
    Router.replace(
      href,
      as,
      { shallow },
    );
  } else {
    Router.push(
      href,
      as,
      { shallow },
    );
  }
};
const navigateToScheduleStudy = (numericId: number) => {
  navigateTo('/schedule/[id]/study',
    `/schedule/${numericId}/study`);
};
const navigateToScheduleDetails = (numericId: number, name?: string) => {
  if (name) {
    navigateTo('/schedule/[...slug]',
      `/schedule/${numericId}/${name}`, true, true);
  } else {
    navigateTo('/schedule/[...slug]',
      `/schedule/${numericId}`);
  }
};
const navigateToFlashcards = (numericId: number, name?: string) => {
  if (name) {
    navigateTo('/flashcards/[...slug]',
      `/flashcards/${numericId}/${name}`, true, true);
  } else {
    navigateTo('/flashcards/[...slug]',
      `/flashcards/${numericId}`);
  }
};
const navigateToPremium = (numericId: number, name: string) => {
  navigateTo('/premium/[...slug]',
    `/premium/${numericId}/${name}`);
};
const navigateToCheckout = (numericId: number, name: string, queryParamValue: string) => {
  Router.push(`/premium/${numericId}/checkout?plan=${queryParamValue}`);
};

const handleSignInNavigation = (isStaticPage: boolean) => {
  const {
    asPath,
    pathname,
    query,
  } = Router;
  const storedRedirectUrl = retrieve(key.redirectUrl);
  const finalRedirectUrl = query.redirectUrl || storedRedirectUrl;

  // replace sandbox with redeem
  if (pathname === '/login' || pathname === '/create-account' || pathname === '/sandbox') {
    if (finalRedirectUrl) {
      Router.replace(finalRedirectUrl);
    } else {
      Router.replace('/dashboard');
    }
  } else if (asPath === '/' || asPath === '/logout' || isStaticPage) {
    Router.push('/dashboard');
  } else {
    Router.push(asPath, undefined, { shallow: true });
  }
};

const shallowRedirectToCheckout = (numericId: number, name: string, queryParamValue: string) => {
  window.history.pushState({}, name,
    `/premium/${numericId}/checkout?plan=${queryParamValue}`);
};

export {
  navigateToScheduleStudy,
  navigateToScheduleDetails,
  navigateTo,
  navigateToCheckout,
  navigateToPremium,
  handleSignInNavigation,
  navigateToFlashcards,
  shallowRedirectToCheckout,
};
