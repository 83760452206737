// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import { Box, Container } from '@material-ui/core';

/* -----------------Props--------------- */
type Props = {
  children: *,
};

/* -----------------Component--------------- */
const AuthContainerPage = (props: Props) => {
  const { children } = props;
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxWidth="xs">
        {children}
      </Container>
    </Box>
  );
};

export default AuthContainerPage;
