// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Constants--------------- */
import { containerType } from '../components/src/components/common/constants';

/* -----------------Nextjs--------------- */
import Router from 'next/router';

/* -----------------Anayltics--------------- */
import {
  trackCustomEvent,
} from '../components/src/analytics/tracking';

/* -----------------Context--------------- */
import { pageVariant, PageContext } from '@components/common/PageContext';

/* -----------------HOC--------------- */
import {
  AuthContext,
} from '@components/hocs/auth';
import {
  key,
  authenticated,
} from '../components/src/common/authutils';
/* -----------------Child components--------------- */
import AuthContainer from '@components/auth/AuthContainer';
import AuthContainerPage from '@components/auth/AuthContainerPage';
import SignIn from '@components/auth/SignIn';

/* -----------------Component--------------- */

const SignInPage = () => {
  const {
    refreshAuth,
  } = React.useContext(AuthContext);
  const isAuthenticated = authenticated(key.loggedIn);
  const { setVariant } = React.useContext(PageContext);

  React.useEffect(() => {
    if (isAuthenticated) {
      Router.push('/dashboard');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (typeof setVariant === 'function') setVariant(pageVariant.auth, null);
    trackCustomEvent({
      eventName: 'login',
    });
  }, [setVariant]);

  return (
    <AuthContainerPage>
      <AuthContainer>
        <SignIn
          refreshAuth={refreshAuth}
          displayMode={containerType.page}
        />
      </AuthContainer>
    </AuthContainerPage>
  );
};

export default SignInPage;
